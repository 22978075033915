<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="运输类型：">
        <el-select
          v-model="formInline.transport"
          clearable
          placeholder="请选择运输类型"
        >
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务类型：">
        <el-select
          v-model="formInline.region"
          clearable
          placeholder="请选择业务类型"
        >
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="渠道：">
        <el-select
          v-model="formInline.channel"
          clearable
          placeholder="请选择渠道"
        >
          <el-option label="日本佐川-NDP" value="4181"></el-option>
          <el-option label="日本佐川-HKM" value="4201"></el-option>
          <el-option label="日本佐川-HKP" value="4222"></el-option>
          <el-option label="日本-F线" value="5701"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="display: flex">
      <el-button size="small" type="primary" @click="handleGet"
        >下载模板</el-button
      >
      &nbsp;&nbsp;
      <el-upload
        class="upload-demo"
        :auto-upload="false"
        :show-file-list="false"
        action="action"
        accept=".xls"
        drag
        :on-change="handleChange"
        :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <!-- <el-button size="small" type="primary">导入 excel</el-button> -->
      </el-upload>
    </div>
    <br />
    <!-- <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="日期"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
    </el-table> -->
  </div>
</template>
<script>
import { importOrder } from "@/api/index";
export default {
  data() {
    return {
      data: [],
      tableData: [],
      formInline: {
        transport: "",
        region: "",
        channel: "",
      },
      content: [],
    };
  },
  methods: {
    async handleGet() {
      window.open("https://riben.e-kc.vip/uploads/日本线下单模板.xls");
    },
    handleChange(file) {
      console.log(file);
      if (file.raw.type === "application/vnd.ms-excel") {
        this.$Export.xlsx(file.raw).then(async (data) => {
          if (this.formInline.channel) {
            console.log(data);
            this.Dataprocess(data.results);
            if (this.content.length === 0) {
              this.$message({
                message: "上传的文件内容为空",
                type: "warning",
                showClose: true,
              });
              return;
            }
            console.log(this.content);
            const res = await importOrder({
              content: this.content,
              product_id: this.formInline.channel,
            });
            let obj = JSON.parse(res.data);
            if (obj.status === 200) {
              this.$message({
                message: obj.msg,
                type: "success",
                showClose: true,
              });
            }
          } else {
            this.$message({
              message: "请先选择渠道",
              type: "warning",
            });
          }
        });
      } else {
        this.$message({
          message: "上传文件必须为 .xls 格式",
          type: "warning",
        });
      }
    },
    changeVal() {
      console.log(this.form.region);
    },
    Dataprocess(data) {
      this.content = [];
      data.forEach((item) => {
        this.content.push({
          sku: item["sku"],
          No: item["订单编号"],
          consignee_city: item["城市"],
          order_customerinvoicecode: item["客户单号"],
          consignee_state: item["州,省"],
          consignee_name: item["收件人姓名"],
          consignee_telephone: item["收件人电话"],
          consignee_postcode: item["收件人邮编"],
          invoice_title: item["海关报关品名"],
          invoice_amount: item["申报价值"],
          invoice_pcs: item["申报品数量"],
          country: item["目的国家"],
          consignee_address: item["联系地址"],
          weight: item["重量"],
        });
      });
    },
    beforeUpload(file) {
      console.log(file.type);
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-form-inline {
  text-align: left;
}
.avue-crud__menu {
  display: none;
}
</style>
