import instance from "./request";

//登录请求
export const loginApi = (data) => {
  return instance({
    url: "/login/index",
    method: "post",
    data,
  });
};

//下单导入
export const importOrder = (data) => {
  return instance({
    url: "/order/importOrder",
    method: "post",
    data,
  });
};

export const getOrderList = (data) => {
  return instance({
    url: "order/order_list",
    method: "post",
    data,
  });
};

export const confirmOrder = (data) => {
  return instance({
    url: "order/confirm_order",
    method: "post",
    data,
  });
};

export const printBill = (data) => {
  return instance({
    url: "order/print_bill",
    method: "post",
    data,
  });
};

//确认修改
export const OrderUpdate = (data) => {
  return instance({
    url: "/Order/update",
    method: "post",
    data,
  });
};

//删除
export const delOrder = (data) => {
  return instance({
    url: "order/del_order",
    method: "post",
    data,
  });
};
//查询轨迹
export const trackList = (data) => {
  return instance({
    url: "order/track_list",
    method: "post",
    data,
  });
};

export const downloadXls = () => {
  return instance({
    url: "order/download_xls",
    method: "get",
  });
};

//导出订单资料
export const outExcel = (data) => {
  return instance({
    url: "/Order/outexcel",
    method: "get",
    data,
  });
};
