import Vue from "vue";
import VueRouter from "vue-router";
const Layout = () => import("@/views/Layout");
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        redirect: "/home",
      },
      {
        path: "/home",
        component: Home,
        name: "录入",
      },
      {
        path: "order",
        component: () => import("@/views/Order"),
        name: "运单管理",
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "notFound",
  //   component: () => import(""),
  // },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
