//vuex：store.js文件内容如下
import Vue from "vue";
import Vuex from "vuex";
// 1. 导入包
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  // 2. 配置为 vuex 的插件
  plugins: [createPersistedState()],
  state: {
    Access_Token: "",
    userInfo: "",
  },
  mutations: {
    saveToken(state, data) {
      state.Access_Token = data.Access_Token;
      state.userInfo = data.info;
    },
    removeToken(state) {
      state.Access_Token = "";
      state.userInfo = "";
    },
  },
});
