//导入路由
import router from "@/router/index";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

// 定义一个白名单
const WHITE_LIST = ["/login"];

//导航守卫判断是否需要token才能登录的页面
router.beforeEach((to, from, next) => {
  NProgress.start();
  const token = JSON.parse(localStorage.getItem("vuex"));
  if (token && token.Access_Token) {
    // 3.0 存在
    //  3.1 判断当前访问的页面是否是 login
    if (to.path === "/login") {
      //    是：跳转到首页
      router.push("/home");
    } else {
      //    不是：向后执行
      next();
    }
  } else {
    const bool = WHITE_LIST.includes(to.path);
    console.log(to.path);
    if (bool) {
      next();
    } else {
      router.push("/login");
    }
  }
});
router.afterEach(() => {
  // to：去的路由
  // from：来的路由
  // 关闭进度条
  //console.log(to, from);
  NProgress.done();
});
