import axios from "axios";

const instance = axios.create({
  // baseURL: "http://riben.e-kc.com",
  baseURL: "https://riben.e-kc.vip",
  // baseURL: `${
  //   process.env.NODE_ENV == "development"
  //     ? "http://riben.e-kc.com"
  //     : "https://riben.e-kc.vip"
  // }`,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 判断 token 是否存在，如果存在直接添加到请求头中
    const token = JSON.parse(localStorage.getItem("vuex"));
    if (token) {
      config.headers.Authorization = `${token.Access_Token}`;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export default instance;
